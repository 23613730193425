
import { reactive, defineComponent, computed, ref, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import {
  useQuery,
  useMutateQuery,
} from "@/core/helpers/common-helper";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import TableSkeleton from "@/components/skeleton/Table.vue";
import Pagination from "@/components/Pagination.vue";
import useChannelList from "@/core/services/compositions/channel/useChannelList";
import moment from 'moment';
import { ElNotification } from 'element-plus'
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { numberFormat } from "@/core/helpers/currency-helper";
import { checkPermission } from '@/router/authentication';
import FilterChannel from "./filter-channel.vue";

export default defineComponent({
  components: {
    Pagination,
    TableSkeleton,
    FilterChannel,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    // init
    const { t } = useI18n();

    // breadcrumb
    setNewPageBreadcrumbs(t("channel.breadcrumb"), [
      { title: t("channel.title") }
    ]);

    const paginationRef = ref(null);
    const { query } = useQuery();
    const jsonPayload = ref(null);
    const jsonRespon = ref(null);

    const form = reactive({
      name: '',
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    })

    const fetchParams = computed(() => ({
      page: query.value.page || 1,
      limit: query.value.limit || 20,
      name: query.value.name || form.name,
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    }));

    const { data, isValidating, mutate: refreshList, error } = useChannelList(() => fetchParams.value);

    watch(error, (error) => {
      if (error.response.status == 403) {
        router.push({ name: "403" });

        return;
      }
      ElNotification({
        title: error.response.data.rc,
        message: error.response.data.message,
        type: 'error',
      })
    });

    watch(query, (query) => {
      if (Object.keys(query).length === 0) {
        Object.assign(form, {
          name: '',
          order_by: 'created_at',
          order: 'DESC',
          ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
        });
      }
    });

    const { mutateQuery } = useMutateQuery();
    const handleChangePage = (nextPage: number) => {
      mutateQuery({
        page: nextPage,
      });
    };

    const filtered = (filters) => {
      if (screen.width <= 760) {
        MenuComponent.hideDropdowns(undefined);
      }
      Object.assign(form, filters);

      mutateQuery({
        page: 1,
        order_by: 'created_at',
        order: 'DESC',
        ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      });
    };

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      data,
      isValidating,
      handleChangePage,
      filtered,
      numberFormat,
      checkPermission,
      moment,
      jsonRespon,
      jsonPayload
    };
  }
});
