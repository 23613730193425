
import { defineComponent, reactive } from "vue";
import moment from 'moment';
import {
  useQuery,
} from "@/core/helpers/common-helper";

interface Filter {
  name: string;
}

export default defineComponent({
  name: "filter-channel",
  components: {},
  setup(props, context) {
    const { query } = useQuery();

    const data = reactive<Filter>({
      name: query.value.name || '',
    });

    const filter = () => {
      context.emit('filtered', data);
    };

    const reset = () => {
      data.name = '';
    };

    return {
      data,
      filter,
      reset,
    };
  },
});
