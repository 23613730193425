import useRequest, { CommonResponse, useRequestOptions } from "./../useRequest";
import { computed } from "vue";

export type useChannelListParams = {
  search?: string;
  page?: number;
  limit?: number;
};

const useChannelList = (
  getParams: () => useChannelListParams = () => ({ page: 1, limit: 20 }),
  options?: useRequestOptions
) => {
  const { data, ...other } = useRequest<CommonResponse<[]>>(
    "/app/entity/channel",
    getParams,
    options
  );
  const enhancedData = computed(() => {
    if (!data.value) return null;
    return data.value.data;
  });

  return {
    data: enhancedData,
    ...other
  };
};

export default useChannelList;
